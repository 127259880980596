.event-card {
    .event-card-dates {
        display: flex;
        justify-content: space-between;

        p {
            margin-bottom: 5px;
        }

        margin-bottom: 20px;
    }
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
}