/* ==================
	8.5 Blog & News
=================== */
.blog-card {
	max-width: 360px;
	border: 2px solid rgba($primary, 0.2);
	background-color: $white;
	border-radius: 10px;
	@include transitionMedium;

	.post-media {
		border-radius: 6px;
		overflow: hidden;
		margin-bottom: 20px;

		img {
			width: 100%;
			height: 350px;
			object-fit: cover;
		}
	}

	.post-title {
		margin-bottom: 25px;
	}

	.post-info {
		padding: 12px;
	}

	@include respond('tab-port') {
		padding: 15px;

		.post-info {
			padding: 5px 5px 10px;
		}
	}

	@include respond('phone') {
		padding: 10px;

		.post-title {
			margin-bottom: 20px;
		}
	}

	&:hover {
		box-shadow: 0px 10px 30px 0px rgba(31, 34, 120, 0.1);
		border: 2px solid $white;
	}
}

.post-meta {
	align-items: center;
	margin-bottom: 15px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	li {
		display: inline-block;
		position: relative;
		font-size: 14px;
		margin-right: 20px;
		color: $secondary;
		line-height: 1;

		&:last-child {
			margin-right: 0;
		}

		&.author {
			a {
				display: flex;
				align-items: center;
			}
		}

		img {
			border-radius: 30px;
			height: 30px;
			width: 30px;
			display: inline-block;
			margin-right: 10px;
		}

		i {
			font-size: 18px;
			margin-right: 5px;
		}

		a {
			color: $secondary;
		}
	}
}

.blog-slide {
	margin-top: -15px;

	.blog-card {
		margin: 15px;
	}

	.slick-active.slick-current+.slick-active {
		.blog-card {
			@include respond('tab-land-min') {
				box-shadow: 0px 10px 30px 0px rgba(31, 34, 120, 0.1);
				border: 2px solid $white;
			}
		}
	}

	.swiper-slide {
		margin-bottom: 10px;
	}

	@include respond('phone') {
		.blog-card {
			margin: 5px;
		}
	}
}

.blog-area {
	.pt-img1 {
		top: 200px;
		left: 100px;

		@include respond('tab-port') {
			top: 170px;
			left: 30px;
		}
	}

	.pt-img2 {
		bottom: 50px;
		left: 150px;

		@include respond('tab-port') {
			bottom: -50px;
			left: 90px;
		}
	}

	.pt-img3 {
		top: 120px;
		right: 200px;

		@include respond('tab-port') {
			top: 100px;
			right: 20px;
		}
	}

	.pt-img4 {
		bottom: 40px;
		right: 100px;

		@include respond('tab-port') {
			bottom: 30px;
			right: 50px;
		}
	}

	.blog-bg-5 {
		position: absolute;
		top: 60px;
		left: 0;
		z-index: -1;
		opacity: 0.2;
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;

		svg {
			width: 100%;
			height: 100%;
			background-repeat: no-repeat;
		}
	}
}